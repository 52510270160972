<template>
  <b-tabs v-model="step" @input="onTabChange">
    <b-tab active>
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Ajout Autres Assertions"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Nom de l'assertion"
            label-for="name-input"
            invalid-feedback="Compléter le nom de l'assertion svp"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="name"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>

      <template #title>
        <feather-icon icon="BarChartIcon" />
        <span>Suivi du dossier</span>
      </template>

      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="InfoIcon" size="18" />
            <span class="align-middle ml-50">Informations sur le Dossier</span>
          </b-card-title>
          <div class="float-right">
            <b-button
              class="btn btn-warning text-right"
              style="margin-right: 5px"
              v-on:click="step = 1"
              >Voir l'Evolution</b-button
            >
            <b-button
              @click="printFile('printableAreaInfo')"
              class="btn btn-success text-right"
              ><feather-icon icon="PrinterIcon" size="18" /> Imprimer Infos. du
              Dossier</b-button
            >
          </div>
        </b-card-header>
        <b-card-body id="printableAreaInfo">
          <b-row>
            <b-col cols="12" md="12">
              <b-img
                style="width: 100%"
                :src="require('@/assets/images/banner/jc-banner.jpg')"
              />

              <p
                cols="12"
                md="12"
                style="
                  padding: 15px;
                  border: 2px solid silver;
                  margin-top: 10px;
                  margin-bottom: 10px;
                "
              >
                <strong
                  style="font-size: 22px; text-black"
                  class="d-flex align-items-center justify-content-center"
                  >INFORMATIONS DU DOSSIER :: REF:
                  {{ folderData.type_folder }} , Imprimé :
                  {{ datetime }}</strong
                >
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="4">
              <table>
                <tr>
                  <td>CODE/REF :</td>
                  <td>
                    <strong>{{ folderData.sku_folder }}</strong>
                  </td>
                </tr>

                <tr>
                  <td>Type de dossier :</td>
                  <td>
                    <strong>{{ folderData.type_folder }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Type de violence :</td>
                  <td>
                    <strong>{{ folderData.type_violence }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Gestionnaire du dossier :</td>
                  <td>
                    <strong>{{ folderData.sku_lawyer }}</strong>
                  </td>
                </tr>

                <tr>
                  <td>Statut du dossier :</td>
                  <td>
                    <strong>{{ folderData.status }}</strong>
                  </td>
                </tr>
              </table>
            </b-col>
            <b-col cols="12" md="5">
              <table>
                <tr>
                  <td>Victime :</td>
                  <td>
                    <strong>{{ folderData.victim_name }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Mois Ouverture dossier :</td>
                  <td>
                    <strong>{{ folderData.created_at }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Type de VBG :</td>
                  <td>
                    <strong>{{ folderData.type_violence }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Détails :</td>
                  <td>
                    <strong>{{ folderData.details }}</strong>
                  </td>
                </tr>

                <tr>
                  <td>Autre n° :</td>
                  <td>
                    <strong>{{ folderData.num_other }}</strong>
                  </td>
                </tr>
              </table>
            </b-col>
            <b-col cols="12" md="3">
              <table>
                <tr>
                  <td>N° PV :</td>
                  <td>
                    <strong>{{ folderData.num_pv }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>N° RMP :</td>
                  <td>
                    <strong>{{ folderData.num_rmp }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>N° RECL :</td>
                  <td>
                    <strong>{{ folderData.num_recl }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>N° RP :</td>
                  <td>
                    <strong>{{ folderData.num_rp }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>N° RC :</td>
                  <td>
                    <strong>{{ folderData.num_rc }}</strong>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card-body>

        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="EditIcon" size="18" />
            <span class="align-middle ml-50">Rapportage </span>

            <feather-icon icon="ActivityIcon" size="18" />
            <span class="align-middle ml-50">Assertion / Indicateur </span>
          </b-card-title>
        </b-card-header>

        <b-card-body>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(addUpdateMonitoringAction)"
              @reset.prevent="resetForm"
            >
              <b-row>
                <b-col cols="12" md="2">
                  <!-- Mois -->
                  <validation-provider
                    #default="validationContext"
                    name="month"
                    rules="required"
                  >
                    <b-form-group
                      label="Mois"
                      label-for="month"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="monitoringData.month"
                        :state="getValidationState(validationContext)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="monthOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="month"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        Selectionner le Mois
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="2">
                  <!-- Mois -->
                  <validation-provider
                    #default="validationContext"
                    name="year"
                    rules="required"
                  >
                    <b-form-group
                      label="Année"
                      label-for="year"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="monitoringData.year"
                        :state="getValidationState(validationContext)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="yearOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="year"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        Selectionner l'année
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row
                class="pb-2"
                v-for="(assertion, a_index) in monitoringData.assertion"
                :key="a_index"
                ref="row"
              >
                <b-col cols="12" md="8" style="border-left: 2px solid #f5f5f5">
                  <b-form-group :label-for="'name.assertion.' + assertion.name">
                    <label
                      ><span class="text-primary">
                        {{ assertion.name }}
                      </span>
                    </label>

                    <b-form-input
                      :id="'name.assertion.' + a_index"
                      v-model="monitoringData.assertion[a_index].value"
                      trim
                    />

                    <!-- <v-select
                        :id="'name.assertion.' + a_index"
                        v-model="monitoringData.assertion[a_index].name"
                        :state="getValidationState(validationContext)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="assertionOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        :on-change="assertionSelectChange"
                      /> -->
                  </b-form-group>
                </b-col>

                <!--  <b-col cols="12" md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="'name.assertion.' + a_index"
                    rules="required"
                  >
                    <b-form-group :label-for="'name.assertion.' + a_index">
                      <label><span class="text-primary"> Valeur </span> </label>

                      <b-form-input
                        :id="'valeur.assertion.' + a_index"
                        v-model="monitoringData[assertionName]"
                        trim
                        :state="getValidationState(validationContext)"
                        :placeholder="
                          `Ex: Valeur de l'assertion/indicateur ` +
                          (a_index + 1)
                        "
                      />

                      <b-form-invalid-feedback>
                        Completer la Valeur de l'assertion
                        {{ a_index + 1 }} svp
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->

                <b-col cols="12" md="3" v-if="a_index >= initAssesstionCount">
                  <b-form-group>
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeEmployer(a_index)"
                    >
                      <feather-icon icon="XIcon" class="mr-10" />
                      <span>Supprimer</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row style="margin-top: 5px">
                <b-col cols="12" md="4">
                  <b-button
                    variant="outline-primary"
                    class="btn-sm float-left"
                    v-b-modal.modal-prevent-closing
                  >
                    <feather-icon icon="PlusIcon" size="12" />
                    <span class="xl-none">Rapporter Une Assertion</span>
                  </b-button>
                </b-col>
              </b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <b-row style="margin-bottom: 20px">
                <b-col cols="12">
                  <b-button
                    ref="refSubmitEl"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      v-if="loading == false"
                      size="19"
                    />

                    Enregistrer les données
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    type="reset"
                    @click="reinitForm"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  >
                    Annuler
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ActivityIcon" />
        <span>Evolution du Dossier</span>
      </template>
      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="ActivityIcon" size="18" />
            <span class="align-middle ml-50"
              >Rapport de l'évolution du Dossier</span
            >
          </b-card-title>
          <div class="float-right">


<b-form-checkbox
      v-model="dualColumnRepport"
      value="A"
      class="custom-control-primary"
    >
      Deux Colonnes
    </b-form-checkbox>
    <b-form-checkbox


            <b-button
              @click="printFile('printableAreaRepport')"
              class="btn btn-success text-right"
            >
              <feather-icon icon="PrinterIcon" size="18" /> Imprimer Rapport
              d'Evolution</b-button
            >
          </div>
        </b-card-header>
        <b-card-body id="printableAreaRepport">
          <b-row>
            <b-col cols="12" md="12">
              <b-img
                style="width: 100%"
                :src="require('@/assets/images/banner/jc-banner.jpg')"
              />

              <p
                cols="12"
                md="12"
                style="
                  padding: 15px;
                  border: 2px solid silver;
                  margin-top: 10px;
                  margin-bottom: 10px;
                "
              >
                <strong
                  style="font-size: 22px; text-black"
                  class="d-flex align-items-center justify-content-center"
                  >EVOLUTION DU DOSSIER :: REF: {{ folderData.type_folder }} ,
                  Imprimé : {{ datetime }}</strong
                >
              </p>
            </b-col>
          </b-row>

          <b-row :v-if="monitoringReportData.length > 0">
            <b-col
              cols="12"
              :md="dualColumnRepport ? `12` : `6`"
              v-for="(repport, r_index) in monitoringReportData"
              :key="r_index"
            >
              <table
                style="
                  border: 1px solid silver;
                  margin-bottom: 10px;
                  width: 100%;
                "
                class="table table-bordered"
              >
                <thead>
                  <tr scope="col">
                    <th colspan="2" scope="col" class="thead-dark">
                      Rapport de la {{ r_index + 1 }}e Etape
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr scope="row">
                    <th>Période :</th>
                    <th>
                      <strong>{{ repport.month }} - {{ repport.year }} </strong>
                    </th>
                  </tr>
                  <tr
                    style="margin-bottom: 20px; border-bottom: 1px solid black"
                  >
                    <th>Déposé :</th>
                    <th>
                      <strong>{{ repport.created_at }} </strong>
                    </th>
                  </tr>

                  <tr
                    v-for="(assertion, a_index) in JSON.parse(
                      repport.assertion
                    )"
                    style="border-bottom: 1px solid silver; margin-bottom: 5px"
                  >
                    <td>{{ assertion.name }}</td>
                    <td>
                      <strong> {{ assertion.value }} </strong>
                    </td>
                  </tr>
                </tbody>
              </table> 
            </b-col>

            <b-col cols="12" md="12">
              <table
                style="
                  border: 1px solid silver;
                  margin-bottom: 10px;
                  width: 100%;
                "
                class="table table-bordered"
              >
                <thead>
                  <tr scope="col">
                    <th scope="col" class="thead-dark">
                     Code Agent:
                    </th>
                    <!-- <th><strong>{{ repport.sku_user }} </strong></th> -->
                  </tr>
                </thead> 
              </table>
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BImg,
  BTab,
  BTabs,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardHeader,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./storeModule";
import DataAddTab from "./FormData.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BImg,
    BTabs,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCardHeader,
    BCardTitle,
    vSelect,
    BForm,
    BFormGroup,
    BFormInput,
    // DataAddTabInformation,
    // DataAddTabSocial,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      loading: false,
      message: "TABLE TITLE",
      collapsed: true,
      folderData: null,
      assertionList: [],
      assertionOptions: [],
      monitoringReportData: [],
      initAssesstionCount: 0,
      name: "",
      nameState: null,
      submittedNames: [],
      datetime: "",
      step: 0,
      dualColumnRepport: false,
    };
  },
  async mounted() {
    if (router.currentRoute.name == "jcfolder-add-new") {
      this.operationTitle = "Nouveau jcfolder";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "jcfolder-view") {
      this.operationTitle = "Détails de la jcfolder";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "jcfolder-edit") {
      this.operationTitle = "Modification de la jcfolder";
      this.operationIcon = "EditIcon";
    }

    // get folder informations
    store
      .dispatch("app-jcfolder/fetchModule", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        this.folderData = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.folderData = undefined;
      });

    // assertions
    await myApi
      .get(`jcassertion`)
      .then((response) => {
        // default action
        var data = response.data.data;
        // console.log(data);
        var i = 0;
        for (i = 0; i < data.length; i++) {
          // options
          this.monitoringData.assertion.push({
            name: data[i].name,
            value: "",
          });
        }

        this.initAssesstionCount = this.monitoringData.assertion.length;
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  watch: {
    step: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        // do your stuff
        // alert(oldVal + " to " + val);
        if (val == 1) {
          // refresh  evolution of folder
          this.getFolderEvolution();
        }
      },
    },
  },
  methods: {
    prevPage() {
      router.push({ name: "jcfolder-list" });
    },
    printFile(divID) {
      // Pass the element id here

      var currentdate = new Date();
      var datetime =
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " à " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      this.datetime = datetime;

      this.$htmlToPaper(divID);
      return;
      // Get HTML to print from element
      const prtHtml = document.getElementById("divID").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    async getFolderEvolution() {
      // assertions
      await myApi
        .get(`jcfoldermonitoring/` + this.monitoringData.sku_folder)
        .then((response) => {
          // default action
          this.monitoringReportData = response.data.data;
        })
        .catch((error) => {
          //
          console.log(error);
        });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
    assertionSelectChange: function (event) {
      console.log(event);
      // this.selectedIndex = selectedIndex;
      // this.monitoringData.assertion.splice(a_index, 1);
    },
    addNewAssertionForm(name) {
      this.monitoringData.assertion.push({
        name: name,
        value: "",
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    updateEmployerForm(a_index, index, val) {
      const { cost, qty, buy_unit, description } = val;
      // ---------------------------------------------------------
      this.monitoringData.assertion[a_index].items[index].cost = cost;
      this.monitoringData.assertion[a_index].items[index].qty_unit = buy_unit;
      if (
        this.monitoringData.assertion[a_index].items[index].qty == null ||
        this.monitoringData.assertion[a_index].items[index].qty == undefined
      ) {
        // default qty
        this.monitoringData.assertion[a_index].items[index].qty = 1;
      }
      this.monitoringData.assertion[a_index].items[index].description =
        description;
    },
    removeEmployer(a_index) {
      this.monitoringData.assertion.splice(a_index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form[0].scrollHeight);
      });
    },
    onTabChange() {
      // tab is changed!
    },
    reinitForm() {
      var now = new Date();
      //
      this.$props.monitoringData.sku_jcfolder = "jcfolder-" + now.getTime();
      this.$props.monitoringData.sku_jcfolder = "";
      this.$props.monitoringData.type = "";
      this.$props.monitoringData.method = "";
      this.$props.monitoringData.direction = "";
      // update row heigth
      this.initTrHeight();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.addNewAssertionForm(this.name);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });

      return;

      // Push the name to submitted names
      this.submittedNames.push(this.name);
    },
    async addUpdateMonitoringAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "jcfoldermonitoring-view") {
        method = "get";
      } else if (router.currentRoute.name == "jcfoldermonitoring-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.monitoringData.id;
      } else {
        method = "post";
        this.actionName = "Enregistrement...";
      }

      // finalise form data

      console.log(this.monitoringData);

      formData = this.monitoringData;

      myApi({
        url: "jcfoldermonitoring" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          console.log(response);

          if (response.status == 200 || response.status == 201) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Voir évolution du dossier ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to evolution
                this.step = 1;
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.actionName = defaultActionText;
          this.showToast(
            "Erreur  Survenu",
             error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    let APP_STORE_MODULE_NAME = "app-jcfolder";

    let monitoringData = ref(null);

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    let resetmonitoringData = () => {
      // this.agentData = this.$props.agentData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmonitoringData);

    const defaultmonitoringData = ref({
      sku_folder: router.currentRoute.params.id,
      year: "",
      assertion: [],
    });

    const assertionDefault = ref({
      name: "",
      value: "",
    });

    const monthOptions = [
      {
        label: "Janvier",
        value: "Janvier",
      },
      {
        label: "Février",
        value: "Février",
      },
      {
        label: "Mars",
        value: "Mars",
      },
      {
        label: "Avril",
        value: "Avril",
      },
      {
        label: "Mai",
        value: "Mai",
      },
      {
        label: "Juin",
        value: "Juin",
      },
      {
        label: "Juillet",
        value: "Juillet",
      },
      {
        label: "Aout",
        value: "Aout",
      },
      {
        label: "Septembre",
        value: "Septembre",
      },
      {
        label: "Octobre",
        value: "Octobre",
      },
      {
        label: "Novembre",
        value: "Novembre",
      },
      {
        label: "Décembre",
        value: "Décembre",
      },
    ];

    const yearOptions = [
      {
        label: "2022",
        value: "2022",
      },
      {
        label: "2023",
        value: "2023",
      },
      {
        label: "2024",
        value: "2024",
      },
      {
        label: "2025",
        value: "2025",
      },
    ];

    // default monitoring data
    monitoringData = defaultmonitoringData;

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      monitoringData,
      defaultmonitoringData,
      assertionDefault,
      yearOptions,
      monthOptions,
      APP_STORE_MODULE_NAME,
    };
  },
};
</script>
